@media screen and (max-width: 768px){
    .title1 {
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      font-size: 35px;
    }
    .det {
      padding-top: 2%;
      padding-left: 5%;
      font-size: 20px;
  }
  }
  @media screen and (min-width: 768px){
      .title1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        font-size: 3.8em;
        letter-spacing: 4px;
      }
      .det {
        padding-top: 2%;
        padding-left: 5%;
        font-size: 1.2em;
    }
    }
.d  {
    height: auto;
    width: 100%;
    /* sbackground-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNTdZt9NwQ1IaRDDeggnPsowPSKjsgifAHYQ&usqp=CAU) ; */
    background-color: rgb(40, 40, 40);
    color: white;
    padding: 2em;
}
.title1{
    padding-top: 5%;
    padding-left: 5%;
}


#bt{
    margin-top: 2%;
    margin-left: 5%;
} 