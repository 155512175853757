.font-con2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}
a {
    text-decoration: white;
    color: white;
}
a:hover {
    color: red;
}
psp {
    width: 80%;
}
@media screen and (max-width: 768px){
    .con2{
        background-color: black;
        color: white;
        display: flex;
        flex-direction: column;
        padding: 5em 2em 5em 2em;
        
    }
  }
  @media screen and (min-width: 768px){
      .con2{
        background-color: black;
        height: auto;
        width: 100%;
        color: white;
        display: flex;
        padding: 5em 2em 5em 2em;
    }
    .a {
        width: 40%;
        padding: 5px;
    }
    .ab{
        width: 30%;
    }
    .ac{
        width: 30%;
    }
    }