    /* Indweserve.css */
@media screen and (max-width: 768px){
    .font-link-ind {
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      font-size: 14px;
    }
  }
  @media screen and (min-width: 768px){
      .font-link-ind {
        font-family: 'Poppins', sans-serif;
        font-weight: 200;
        font-size: 1.2rem;
      }
    }
/* Hero section styles */
.hero-container {
    position: relative;
    height: 45vh;
    background-image: url('https://images.pexels.com/photos/209251/pexels-photo-209251.jpeg?auto=compress&cs=tinysrgb&w=800');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.hero-content {
    position: relative;
    z-index: 1;
    text-align: center;
}

.hero-content h1 {
    font-size: 3.3rem;
    margin-bottom: 20px;
    font-weight: bolder;
}

/* Image grid styles */
.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(31%), 1fr));
    /* Three images per row with gap */
    gap: 1.8em;
    max-width: 1300px;
    margin: 2rem auto;
}

.image {
    width: 100%;
    height: auto;
}

.image-inner {
    position: relative;
    width: 100%;
    padding-top: 75%;
    overflow: hidden;
    border-radius: 5%;
}

.image-inner img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
    /* Transition for opacity */
}

.image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background-color: rgb(255, 83, 83);
    border-left: 2px solid black;
    border-right: 2px solid black;
    padding-left: 60%;
    padding-right: 40%;
    font-size: 2em;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    /* Transition for opacity */
}

.image-inner:hover img {
    opacity: 0.7;
    /* Reduce image opacity on hover */
}

.image-inner:hover .image-text {
    opacity: 1;
    /* Increase text opacity on hover */
}