.font-link-contact
    {
        width: 90%;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 1.2rem;
    }
@media screen and (min-width: 769px){
    .font-link-contact
    {
        width: 35%;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 1.2rem;
    }
}
.head{
    color: red;
    font-weight: 500;
}
.shadow-effect {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Horizontal offset, vertical offset, blur radius, spread radius, and color */
    transition: box-shadow 0.3s ease; /* Add a transition effect */
  }
  
  /* Optional: Hover effect to change the shadow */
  .shadow-effect:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); /* Change the shadow on hover */
  }
  