.desktop-only {
    display: none; /* Hide the component by default */
  }
  .inq{
    color: white;
    margin: 0 5em;
    margin-top: -9px;
    padding: 0.3em 0.6em;
    margin-bottom: -5px;
  }
  .inq:hover{
    color: red;
    font-weight: 500;
    background-color: white;
  }
  @media (min-width: 1025px) {
    .desktop-only {
      display: block; /* Show the component on screens larger than 768px */
      position: fixed;
      top: 0;
      z-index: 10;
      width: 100%;
      width: 100%;
    }
  }
.con {
    margin-left: 2em;
    margin-right: 0.5em;
    align-items: center;
}

.Icon2 {
    float: right;
    margin: 0 0.5em;
    align-items: center;
    /* background-color: red; */
    color: white;
    border-radius: 30%;
}
div.font-link-line
    {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 1.2rem;
    }