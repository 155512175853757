@media screen and (max-width: 768px){
  .font-link-prodhome {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 14px;
  }
}
@media screen and (min-width: 768px){
    .font-link-prodhome {
      font-family: 'Poppins', sans-serif;
      font-weight: 200;
      font-size: 1.2rem;
    }
  }
  .client-bo-prod {
    border-bottom: 0.15em solid red;
  }
  @media screen and (max-width: 768px){
    #mm {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 20px;
    }
  }
  @media screen and (min-width: 768px){
      #mm {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 2rem;
      }
      h2{
        margin-bottom: 1em
      }
    }
.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* margin: 4em; */
  /* border: 2px solid red; */
  margin-bottom: 20px;
  text-align: center;
}
.product-item-reverse {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* margin: 4em; */
  /* border: 2px solid red; */
  margin-bottom: 20px;
  text-align: center;
}

.product-image {
  width: 30%;
  /* min-width: 40%; */
  /* height: 70%; */
  overflow: hidden;
  /* padding-top: 2em;
    padding-bottom: 2em; */
  /* border: 8px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-description {
  /* border: 2px solid red; */
  /* padding: 2em; */
  /* border: 2px solid black; */
  width: 70%;
  display: flex;
  padding-top: 2em;
  /* padding-left: 6em; */
  /* padding-right: 6em; */
  flex-direction: column;
}

.product-description p{
  margin-top: 5px;
}

.product-image img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 768px){
  .disc {
    display: none;
  }
}
@media screen and (max-width: 768px){
  .product-description{
    width: 55%;
    /* border: 2px solid black ; */
  }
  .product-image{
    width: 45%;
  }
}

@media screen and (min-width: 768px){
  .product-image{
    width: 55%;
  }
  .product-image img {
    max-width: 360px;
    height: auto;
  }
}
@media screen and (min-width: 768px){
  .product-description {
    /* display: none; */
    padding-top: 1.5em;
    padding-left: 0;
    padding-right: 0;
  }

}
@media screen and (min-width: 1280px){
  .product-image{
    width: 55%;
  }
  .product-image img {
    max-width: 500px;
    height: auto;
  }
}
@media screen and (min-width: 1400px){
.products-container{  
  padding: 0 10vw;
}
}
/* .font-link-prodhome {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  /* font-size: 1.2rem; 
} */
.btncls {
  background-color: red;
  color: white;
  font-size: 1em;
  border-radius: 0;
}
@media screen and (max-width: 470px){
.product-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid black; */
}
.product-item-reverse{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid black; */
}
.product-image{
  max-width: 300px;
  width: 80%;
}
.product-description{
  width: 80%;
}
.product-item-reverse {
  display: flex;
  flex-direction: column-reverse;
}
}
.product-item-reverse {
  display: flex;
  flex-direction: row3;
}