/* ThreeCardComponent.css */
.card-fixed-height .card-body {
    height: 200px; /* Adjust the height as per your design */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .lift-up {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }

  .card-fixed-height .card {
    border: 2em solid rgba(232, 15, 15, 0.3); /* 5px wide border with opacity */
  }

  .lift-up:hover {
    transform: translateY(-5px);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .card-content {
    padding-top: 1.5em; /* Adjust the padding to accommodate the icon */
  }
  
  .position-absolute {
    position: absolute;
  }
