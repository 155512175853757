.imgAirComp {
    height: auto;
    width: 100%;
}

.imgspec {
    height: auto;
    width: 100%;

}
.specd2{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.font-prod1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

@media screen and (max-width: 768px) {
    .main {
        flex-direction: column;
    }
    .specd2{
        width: 90%;
    }
    .imgdiv {
        width: 100%;
        padding: 5%;
        align-items: center;
        /* border: 2px solid black; */
    }

    .specdiv {
        width: 100%;
        /* border: 2px solid black; */
        align-items: center;
    }
}

@media screen and (min-width: 768px) {
    .main {
        flex-direction: row;
    }
    .specd2{
        width: 80%;
    }
    .imgdiv {
        width: 50%;
        align-items: center;
        /* border: 2px solid black; */
    }

    .specdiv {
        width: 60%;
        /* border: 2px solid black; */
        align-items: center;
    }

    .imgAirComp {
        max-width: 500px;
    }
}

.lv {
    /* padding-left: 6px; */
    border-bottom: 2px solid red;
    border-left: 2px solid red;
    font-weight: 500;
    margin-left: 20px;
    margin-right: 20px;
}

@media screen and (min-width: 1280px) {
    .lv {
        /* border: 2px solid black; */
        /* margin-left: 8vw;
        margin-right: 8vw;    */
    }
    .specd2{
        width: 80%;
    }
}