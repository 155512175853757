/* LogoCarousel.css */
.logo-carousel-home {
  width: 100%; /* Use the entire width of the parent container */
  margin: 0;
  overflow: hidden;
  padding: 0;
}

/* Set the width of individual slides */
.logo-carousel-home .slick-list {
  width: 100%;
}

/* Customize styles for logos if needed */
.logo-carousel-home img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Styling */
.font-link-home {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
}

.client-border-carousel {
  border-bottom: 0.15em solid red;
}
