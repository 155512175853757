/* Footer.css */
.fot {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}
.footer {
    background-color: rgb(23, 23, 23);
    padding: 20px 0;
    color: white;
    bottom: 0;
    width: 100%;
  }
  
  .footer p {
    margin: 0;
  }
  