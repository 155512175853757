
    input.form-control:focus {
        border-color: red;
        box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25); /* Optional: Add a box shadow for better visibility */
    }
    .vkk{
        color: white;
        margin-top: -40px;
        margin-bottom: 10px;
        padding: 0.8em;
        background-color: red;
        text-align: center;
        justify-content: center;
    }