.card-container {
    display: flex;
    flex-wrap: wrap; /* Allow cards to wrap to the next line */
    justify-content: space-evenly;
    margin: 10px;
}

/* Style for each card */
.card1 {
    width: 90%;
    max-width: 390px; /* Set a maximum width for better responsiveness */
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-bottom: 20px; /* Add margin between cards */
}

/* Hover effect - Popping out */
.card1:hover {
    transform: scale(1.10);
}

/* Style for the image inside the card */
.card1 img {
    width: 100%; /* Make the image responsive */
    height: auto; /* Ensure the aspect ratio is maintained */
    object-fit: cover;
}

/* Media query for smaller screens */
@media screen and (max-width: 600px) {
    .card1 {
        width: 80%; /* Cards take up 100% width on smaller screens */
        max-width: none; /* Remove max-width for full-width cards */
    }
}
