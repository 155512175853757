/* LogoCarousel.css */
/* @media screen and (max-width: 768px){
  .font-link-client {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
  }
}
@media screen and (min-width: 768px){
    .font-link-client {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 2rem;
    }
  } */
.client-border {
  border-bottom: 0.15em solid red;
}
.container {
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}

.logo-carousel {
  width: 100%;
  margin: 1.5em auto;
  padding: 0 1rem;
  height: 10vh;
  box-sizing: border-box;
}
@media screen and (max-width: 768px){
.logo-carousel img {
  width: 100%;
  padding-left: 1.5em;
  padding-right: 1.5em;
  height: 80px;
  display: block;
  margin: 0 auto;
}}
@media screen and (min-width: 768px){
  .logo-carousel img {
    width: 100%;
    padding-left: 1.5em;
    padding-right: 1.5em;
    height: 85px;
    display: block;
    margin: 0 auto;
  }}
  @media screen and (min-width: 1600px){
    .logo-carousel img {
      width: 100%;
      padding-left: 1.5em;
      padding-right: 1.5em;
      height: 100px;
      display: block;
      margin: 0 auto;
    }}
  