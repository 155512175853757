.sparediv{
    padding-left: 10vw;
    padding-right: 10vw;
    font-family: 'Poppins', sans-serif;
    }
@media screen and (max-width: 768px){
    .spare-image {
        width: auto;
        height: 80px;
      }
}
@media screen and (min-width: 768px){
    .spare-image {
        width: auto;
        height: 130px;
      }
}
@media screen and (min-width: 768px){
    .spare-image {
        width: auto;
        height: 100px;
      }
}
@media screen and (min-width: 1200px){
    .spare-image {
        width: auto;
        height: 160px;
      }
}
@media screen and (min-width: 1400px){
    .spare-image {
        width: auto;
        height: 180px;
      }
    }
.spare-border {
    border-bottom: 0.15em solid red;
    padding-bottom: 7px;
}