.font-home {
    font-family: 'Poppins', sans-serif;
}
@media screen and (min-width: 768px){
    .title{
      font-size: 3em;
      font-weight: 700;
      border-bottom: 2px solid red;
      letter-spacing: 2px;
  }
  .different{
      display: flex;
      text-align: left;
      flex-direction: column;
  }
  .cover{
      font-size: 1.5em;
      font-weight: 300;
  }
  }
  @media screen and (min-width: 992px){
      .title{
          font-size: 2em;
          font-weight: 600;
          border-bottom: 2px solid red;
      }
      .different{
          display: flex;
          flex-direction: row;
          text-align: left;
      }
      .cover{
          font-size: 1.3em;
          font-weight: 200;
      }
  }

@media screen and (min-width: 1280px){
    .title{
        font-size: 3em;
        font-weight: 600;
        border-bottom: 2px solid red;
    }
    .different{
        display: flex;
        flex-direction: row;
        text-align: left;
        padding-left: 6vw;
        padding-right: 6vw;

    }
    .cover{
        font-size: 1.6em;
        font-weight: 200;
    }
  }

.details{
    word-wrap: normal;
    /* font-size: large; */
    word-spacing: 3px;
    /* text-align: left; */
}
.check {
    font-size: 30px;
    color: red;
    margin-right: 5px;
    /* margin-left: -40px; */
}

h2{
    font-weight: 600;
    color: rgb(39, 39, 124);
}