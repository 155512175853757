/* Navbar.css */
/* Style the navigation links */

.navitem {
  color: black;
  margin: 25px;
  text-decoration: none;
  /* Remove default underline */
  position: relative;
  padding-bottom: 0.5em;
  transition: color 0.3s ease, border-bottom-color 0.3s ease;

}
/* tablet */
  .font-link-nav {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

.tp {
  margin-top: 48px;
}
@media (max-width: 1024px) {
  .tp {
    margin-top: 0;
    background-color: rgb(255, 251, 251);
  }
}
#togle {
  background-color: rgb(206, 206, 206);
  margin-right: 10px;
  /* margin-right: 2rem; */
}

/* Underline effect */
.navitem::after {
  content: '';
  position: absolute;
  bottom: -1px;
  /* Adjust the distance of the underline from text */
  left: 0;
  width: 0;
  border-bottom: 0.2em solid transparent;
  /* Initial transparent underline */
  transition: width 0.3s ease, border-bottom-color 0.3s ease;
  /* Transition for underline width and color */
}

/* Expand underline and change color on hover */
.navitem:hover::after {
  width: 100%;
  /* Expand underline on hover */
  border-bottom-color: red;
  /* Change underline color on hover */
}

/* Change text color to red on hover */
.navitem:hover {
  color: red;
}
  #img1 {
    width: 18em;
  }
@media (max-width: 430px){
  #img1 {
    width: 14em;
    margin-left: 10px;
  }
}
@media (min-width: 430px){
  #img1 {
    margin-left: 45px;
  }
}
@media (min-width: 991px){
  #img1 {
    margin-left: 40px;
    margin-right: 40px;
  }
}