.imgAirComp{
    height: auto;
    width: 100%;
}
.specimg{
    height: auto;
    width: 100%;
}
.specd{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 768px){
    .main {
        flex-direction: column;
    }
    .specd{
        width: 90%;
    }
    .font-prod1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        font-size: 30px;
      }
    .imgdiv {
        width: 100%;
        padding: 5%;
        align-items: center;
        /* border: 2px solid black; */
    }
    .specdiv{
        width: 100%;
        /* border: 2px solid black; */
        align-items: center;
    }
  }
  @media screen and (min-width: 768px){
    .main {
        flex-direction: row;
    }
    .specd{
        width: 80%;
    }
    .font-prod1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 3rem;
      }
    .imgdiv {
        width: 50%;
        align-items: center;
        /* border: 2px solid black; */
    }
    .specdiv{
        width: 60%;
        /* border: 2px solid black; */
        align-items: center;
    }
    }
.bv{
    /* padding-left: 6px; */
    border-bottom: 2px solid red;
    border-left: 2px solid red;
    font-weight: 500;
    margin-left: 20px;
    margin-right: 20px;
}
@media screen and (min-width: 1280px){
    .bv{
        margin-left: 8vw;
        margin-right: 8vw;   

    }
    .specd{
        width: 80%;
    }
}