/* Indweserve.css */
@media screen and (max-width: 768px){
    .font-link-Produc {
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      font-size: 14px;
    }
  }
  @media screen and (min-width: 768px){
      .font-link-Produc {
        font-family: 'Poppins', sans-serif;
        font-weight: 200;
        font-size: 1.2rem;
      }
    }
/* Hero section styles */
.hero-container1 {
    position: relative;
    height: 45vh;
    background-image: url('https://images.pexels.com/photos/209251/pexels-photo-209251.jpeg?auto=compress&cs=tinysrgb&w=800');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.hero-overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.hero-content1 {
    position: relative;
    z-index: 1;
    text-align: center;
}

.hero-content1 h1 {
    font-size: 3.3rem;
    margin-bottom: 20px;
    font-weight: bolder;
}